import React from 'react';
import Typography from './Typography';
import { Link } from '@mui/material';
import LinkedIn from '@material-ui/icons/LinkedIn';
import { Grid } from '@mui/material';

const PersonGrid = (props) => {
    const { title, people } = props;

    const peopleCards = people.map((person, index) => {
        return(
            <Grid key={index} item xs={12} md={6} lg={4}
              sx={{
                textAlign: 'center',
                justifyContent: 'center'
              }}
            >
              <img alt={person.name} src={person.image} style={{ width: '200px' }}/>
              <Typography type='heading' preset={2}>{person.name}</Typography>
              <Typography type='paragraph' preset={2}>
                {person.roles.map((role, index) => {
                    return (
                        <React.Fragment key={index}>
                            {role}
                        </React.Fragment>)
                    })
                }
              </Typography>
              {person.link &&
              <Link href={person.link} target='_blank' color='primary'>
                <LinkedIn
                  fontSize='large'
                />
              </Link>}                
            </Grid>
        )});

    return (
        <>
            <Typography
            type='heading'
            preset={1}
            style={{
                textAlign: 'center',
                marginBottom: 56,
            }}>
                {title}
            </Typography>
            <Grid container direction='row' rowSpacing={4} style={{ justifyContent:'center', }}>
                {peopleCards}
            </Grid>
        </>
    )
}

export default PersonGrid;