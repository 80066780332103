import React from 'react';

import Grid from '@mui/material/Grid';

import Page from '../components/Page';
import Section from '../components/Section';
import Typography from '../components/Typography';

import PersonGrid from '../components/PersonGrid';
import graphicMedal from '../images/medal.svg';
import syed from '../images/syed.svg';
import timd from '../images/timd.svg';
import timp from '../images/timp.svg';
import andrew from '../images/andrew_tindell.svg';
import ian from '../images/ian.svg';
import davidvaux from '../images/david_vaux.svg';
import eric from '../images/erichren.svg';
import simonk from '../images/simonkos.svg';
import heatherl from '../images/heatherlumley.svg';

const executives = [
  {
    name: 'Syed Ahmed',
    roles: ['CEO & Executive Director'],
    link: 'https://www.linkedin.com/in/syedahmed/',
    image: syed
  },
  {
    name: 'Eric  Hren',
    roles: ['Chief Growth Officer'],
    link: 'https://www.linkedin.com/in/eric-j-hren-074a5810/',
    image: eric
  },
  {
    name: 'Heather Lumley',
    roles: ['General Manager - Canada'],
    link: 'https://www.linkedin.com/in/heather-lumley-m-a-che-b3932b20/',
    image: heatherl
  },
  {
    name: 'Tim Duncan',
    roles: ['Chief Technology Officer'],
    link: 'https://www.linkedin.com/in/timpduncan/',
    image: timd
  },
  {
    name: 'Tim Paciolla',
    roles: ['Chief Product Officer'],
    link: 'https://www.linkedin.com/in/timpaciolla/',
    image: timp
  },
];

const board = [
  {
    name: 'David Vaux',
    roles: ['Chair'],
    link: '',
    image: davidvaux
  },
  {
    name: 'Dr. Andrew Tindell',
    roles: ['Director'],
    link: 'https://www.linkedin.com/in/atindell/',
    image: andrew
  },
  {
    name: 'Dr. Simon Kos',
    roles: ['Director'],
    link: 'https://www.linkedin.com/in/simonkos/',
    image: simonk
  },
];

const advisors = [
  {
    name: 'Prof. Ian Hickie',
    roles: ['Scientific Advisor'],
    link: 'https://www.linkedin.com/in/ian-hickie-0b299a69/',
    image: ian
  }
];

const WhoWeAre = () => {

  return (
    <Page title='Who we are'>
      {/* Hero */}
      <Section wide>
        <Grid container direction='row-reverse'>
          <Grid item xs={12} md={6}
            sx={{
              textAlign: 'center',
            }}
          >
            <img alt='Medal' src={graphicMedal}/>
          </Grid>
          <Grid item xs={12} md={6}
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography type='hero' preset={1}>Who we are</Typography>
            <Typography type='paragraph' preset={2}>
              Mental health care is often only discussed when it needs help, but just like physical health, it shouldn't be relegated to the realm of reactive solutions but instead always be nurtured, celebrated, and maintained.
            </Typography>
            <Typography type='paragraph' preset={2}>
              We believe the world works better when people's individual mental health care is supported with the right tools at the right time. That's why we made Innowell. We're digital specialists, academics, technologists, clinical professionals, people with lived experiences, and we've come together to unlock better mental health care for everyone. 
            </Typography>
          </Grid>
        </Grid>
      </Section>

      {/* Leadership team */}
      <Section theme='primary' wide>
        <PersonGrid title='Leadership Team' people={executives} />
      </Section>

      {/* Board */}
      <Section theme='secondary' wide>
        <PersonGrid title='Board' people={board} />
      </Section>
      
      {/* Advisors */}
      <Section theme='primary' wide>
        <PersonGrid title='Advisors' people={advisors} />
      </Section>
    </Page>
  )
};

export default WhoWeAre;
